import React, { useEffect } from 'react'
import { Routes, useLocation } from 'react-router-dom'
import './App.css'
import { AppRoutes } from './AppRoutes'

function App() {
  const location = useLocation()
  const isSideBarApp = location.pathname.startsWith('/app')
  useEffect(() => {
    if (isSideBarApp) {
      document.body.classList.remove('full-screen')
    } else {
      document.body.classList.add('full-screen')
    }
  }, [isSideBarApp])
  return (
    <>
      <Routes>{AppRoutes}</Routes>
    </>
  )
}

export default App
