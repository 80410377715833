import { Link } from 'react-router-dom'
import { Locale } from '../components/Locale'

export default function NotFound() {
  return (
    <div style={{ marginTop: '240px', textAlign: 'center' }}>
      <p style={{ margin: '10px 0' }}>
        <Locale en="Coming Soon" zh_cn="敬请期待" zh_hk="敬請期待" />{' '}
        <i className="fa-solid fa-wrench"></i>
      </p>
      <p>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <button
            style={{
              padding: '10px 20px',
              background: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            <Locale en="Back to Home Page" zh_cn="返回主页" zh_hk="返回主頁" />
          </button>
        </Link>
      </p>
    </div>
  )
}
