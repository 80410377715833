// This file is generated automatically
// Don't edit this file directly

import { call, toParams } from './utils'

export let api_origin = '/api'

// POST /api/credit/top_up
export function topUpCredit(input: TopUpCreditInput): Promise<TopUpCreditOutput & { error?: string }> {
  return call('POST', api_origin + `/credit/top_up`, input.body)
}
export type TopUpCreditInput = {
  body: {
    unit_amount: number
    quantity: number
  }
}
export type TopUpCreditOutput = {
  url: string
}

// GET /api/credit/top_up/:top_up_id/_ingest
export function _topUpCreditIngest(input: _topUpCreditIngestInput): Promise<_topUpCreditIngestOutput & { error?: string }> {
  let { params } = input
  return call('GET', api_origin + `/credit/top_up/${params.top_up_id}/_ingest`)
}
export type _topUpCreditIngestInput = {
  params: {
    top_up_id: number
  }
}
export type _topUpCreditIngestOutput = {
}

// GET /api/credit/top_up/:top_up_id/status
export function topUpCreditStatus(input: TopUpCreditStatusInput): Promise<TopUpCreditStatusOutput & { error?: string }> {
  let { params } = input
  return call('GET', api_origin + `/credit/top_up/${params.top_up_id}/status`)
}
export type TopUpCreditStatusInput = {
  params: {
    top_up_id: number
  }
}
export type TopUpCreditStatusOutput = {
  top_up_status: string
}
