import SideBarApp from './SideBarApp'
import BottomBarApp from './BottomBarApp'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

type State = 'side-bar' | 'bottom-bar'

function calcState(): State {
  if (window.innerWidth > window.innerHeight) {
    return 'side-bar'
  } else {
    return 'bottom-bar'
  }
}

export default function MainApp() {
  const [state, setState] = useState<State>(calcState)
  useEffect(() => {
    function check() {
      setState(calcState())
    }
    window.addEventListener('resize', check)
    return () => {
      window.removeEventListener('resize', check)
    }
  }, [])
  if (state === 'side-bar') return <SideBarApp />
  if (state === 'bottom-bar') return <BottomBarApp />
  return (
    <main>
      <Outlet />
    </main>
  )
}
