import { useState } from 'react'
import { langOptions, Locale, useLang } from './Locale'
import './LanguageOption.css'
import { useGlobalClick } from '../hooks/useGlobalClick'

export default function LanguageOption() {
  const [isLanguageDropdownOpen, setLanguageDropdownOpen] = useState(false)
  const { lang, setLang } = useLang()

  const toggleLanguageDropdown = () => {
    setLanguageDropdownOpen(!isLanguageDropdownOpen)
  }

  useGlobalClick(target => {
    if (target.closest('.lang-button')) return
    setLanguageDropdownOpen(false)
  })

  return (
    <div className="lang-Section">
      <button className="lang-button" onClick={toggleLanguageDropdown}>
        <i style={{ marginRight: '3px' }} className="fa-solid fa-globe"></i>
        <Locale en="Language" zh_cn="语言" zh_hk="語言" />
      </button>
      {isLanguageDropdownOpen && (
        <div className="lang-dropdown">
          {langOptions.map(option => (
            <button key={option.lang} onClick={() => setLang(option.lang)}>
              {option.label}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
