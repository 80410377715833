import { useLayoutEffect } from 'react'

export function useGlobalClick(onGlobalClick: (target: HTMLElement) => void) {
  useLayoutEffect(() => {
    const onClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement
      onGlobalClick(target)
    }
    document.addEventListener('click', onClick)
    return () => {
      document.removeEventListener('click', onClick)
    }
  }, [])
}
