import { Link, useLocation } from 'react-router-dom'
import { useStateProxy } from 'use-state-proxy'
import { useState, useEffect } from 'react'
import { useToken } from '../hooks/useToken'
import { getMenuItems, MenuItem } from './MenuItems'
import { useLang } from './Locale'
import { translate } from './Locale'
import { useRole } from '../hooks/useRole'

export function SideBar() {
  const { token } = useToken()
  const [is_open_menu, set_is_open_menu] = useState(true)
  const role = useRole()
  const menuItems = getMenuItems({ role })

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 550) {
        set_is_open_menu(false)
      } else {
        set_is_open_menu(true)
      }
    }

    handleResize() // Set initial state based on window width

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <div className={'side-menu' + (is_open_menu ? ' open' : '')}>
      <div className="side-menu--header">
        <div
          className="side-menu--logo"
          onClick={() => set_is_open_menu(!is_open_menu)}
        >
          <img alt="Logo" src="/images/feelings.png" />
        </div>
        <div className="side-menu--title">
          <span>Feelings</span>
        </div>
        <button
          className="toggleButton"
          onClick={() => set_is_open_menu(!is_open_menu)}
        >
          <i className="fa-solid fa-bars"></i>
        </button>
      </div>
      <nav>
        <ul>
          {menuItems.map(item => (
            <SideBarMenuItem key={item.label.en} {...item} />
          ))}
        </ul>
      </nav>
    </div>
  )
}

function SideBarMenuItem(props: MenuItem) {
  const location = useLocation()
  const state = useStateProxy({ is_hover: false })
  const { lang } = useLang()
  return (
    <li>
      <Link
        to={props.href}
        className={`side-menu--item ${
          location.pathname === props.href ? 'selected' : ''
        }`}
        onMouseEnter={() => (state.is_hover = true)}
        onMouseLeave={() => (state.is_hover = false)}
      >
        <i className={'side-menu--icon ' + props.icon}></i>{' '}
        <span className="side-menu--label">{translate(lang, props.label)}</span>
      </Link>
    </li>
  )
}
