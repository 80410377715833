import { Link } from 'react-router-dom'
import { Locale } from '../components/Locale'

export default function NotFound() {
  return (
    <div style={{ marginTop: '240px', textAlign: 'center' }}>
      <p style={{ margin: '10px 0' }}>
        <Locale
          en="Oops! The page you're looking for can't be found"
          zh_cn="哎呀！页面好像走丢了"
          zh_hk="很抱歉，無法找到您嘗試瀏覽的網頁"
        />{' '}
        <i className="fa-solid fa-question"></i>
      </p>
      <p>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <button
            style={{
              padding: '10px 20px',
              background: '#007bff',
              color: '#fff',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            <Locale en="Back to Home Page" zh_cn="返回主页" zh_hk="返回主頁" />
          </button>
        </Link>
      </p>
    </div>
  )
}
