import React, { useState, useEffect } from 'react'
import { GetUserProfileOutput, getUserProfile } from '../../src/sdk/user'
import * as userAPI from '../../src/sdk/user'
import './Login.css'
import { useToken } from '../hooks/useToken'
import { useStateProxy } from 'use-state-proxy'
import {
  Link,
  Navigate,
  redirect,
  useNavigate,
  useRoutes,
} from 'react-router-dom'
import { topUpCredit } from '../sdk/credit'
import { triggerAsyncId } from 'async_hooks'
import {
  langOptions,
  translate,
  Locale,
  LocaleVariant,
  useLang,
} from '../components/Locale'
import LanguageOption from '../components/LanguageOption'

function Login() {
  const state = useStateProxy({
    email: '',
    password: '',
    confirm_password: '',
    is_email_used: null as null | boolean,
    topup_value: 0,
  })
  const mode = state.email && state.is_email_used ? 'login' : 'register'

  const { token, setToken, logout } = useToken()

  const [profile, setProfile] = useState<GetUserProfileOutput | null>(null)
  const { lang, setLang } = useLang()

  const navigate = useNavigate()

  useEffect(() => {
    if (!state.email) return
    state.is_email_used = null
    userAPI
      .checkEmail({ query: { email: state.email } })
      .then(json => (state.is_email_used = json.is_registered))
  }, [state.email])

  useEffect(() => {
    if (token) {
      getUserProfile({}).then(setProfile)
    }
  }, [token])

  async function login() {
    let json = await userAPI.login({
      body: {
        email: state.email,
        password: state.password,
      },
    })
    setToken(json.token)
  }

  async function register() {
    let json = await userAPI.register({
      body: {
        email: state.email,
        password: state.password,
      },
    })
    setToken(json.token)
  }
  //!TODO: replace localhost with actual domain
  async function topup() {
    let response: {
      error?: string
      url?: string | undefined
    } = await topUpCredit({
      body: {
        unit_amount: state.topup_value * 100,
        quantity: 1,
      },
    })
    /*redirect to*/
    window.location.href = response.url ? response.url : '/app/library'
    console.log(response, response.url ? response.url : '/app/library')
  }

  useEffect(() => {
    if (token) {
      navigate('/app/profile')
    }
  }, [token])

  function renderBody() {
    if (token) {
      return (
        <>
          <p>
            <Locale
              en="You are already logged in. Redirecting to the profile page..."
              zh_cn="您已登录，即将跳转至个人资料页面..."
              zh_hk="您已登入，即將跳轉至個人資料頁面..."
            />
          </p>
          <Link to="/app/profile">
            <button>
              <Locale
                en="Go to Profile"
                zh_cn="前往个人资料"
                zh_hk="前往個人資料"
              />
            </button>
          </Link>
        </>
      )
    }
    return (
      <>
        <form>
          <h1>
            {translate(lang, {
              en: mode === 'login' ? 'Login' : 'Login / Register',
              zh_cn: mode === 'login' ? '登录' : '登录 / 注册',
              zh_hk: mode === 'login' ? '登入' : '登入 / 註冊',
            })}
          </h1>
          <input
            type="text"
            autoComplete="email"
            onChange={event => (state.email = event.target.value)}
            placeholder={translate(lang, {
              en: 'Email',
              zh_cn: '邮箱',
              zh_hk: '電郵',
            })}
          />
          <input
            type="password"
            autoComplete="current-password"
            onChange={event => (state.password = event.target.value)}
            placeholder={translate(lang, {
              en: 'Password',
              zh_cn: '密码',
              zh_hk: '密碼',
            })}
          />
          {mode == 'register' ? (
            <input
              type="password"
              autoComplete="off"
              onChange={event => (state.confirm_password = event.target.value)}
              placeholder={translate(lang, {
                en: 'Confirm Password',
                zh_cn: '确认密码',
                zh_hk: '確認密碼',
              })}
            />
          ) : null}
        </form>

        {mode != 'login' ? (
          <button
            disabled={state.password !== state.confirm_password}
            onClick={register}
          >
            <Locale
              en="Login / Register"
              zh_cn="登录 / 注册"
              zh_hk="登入 / 註冊"
            />
          </button>
        ) : (
          <button onClick={login}>
            <Locale en="Login" zh_cn="登录" zh_hk="登入" />
          </button>
        )}
      </>
    )
  }

  return (
    <>
      <div className="body">
        <LanguageOption />

        <div className="reg-container">{renderBody()}</div>
      </div>
    </>
  )
}

export default Login
