import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import useStorageState, { createMemoryStorage } from 'react-use-storage-state'

export function useMusicState() {
  const [playingMusic, setPlayingMusic] = useStorageState('play-music', true)
  const [musicVolume, setMusicVolume] = useStorageState('music-volume', 0.25) // Initialize with max volume
  return { playingMusic, setPlayingMusic, musicVolume, setMusicVolume }
}

const memoryStorage = createMemoryStorage()

function useHasTouch() {
  const [hasTouch, setHasTouch] = useStorageState(
    'has-touch',
    false,
    memoryStorage,
  )
  return { hasTouch, setHasTouch }
}

export function Music(props: { url: string }) {
  const { url } = props
  const { playingMusic, setPlayingMusic, musicVolume, setMusicVolume } =
    useMusicState()
  const { hasTouch, setHasTouch } = useHasTouch()
  const audioRef = useRef<HTMLAudioElement>(null)
  const audio = audioRef.current

  useEffect(() => {
    if (hasTouch && url && audio && playingMusic) {
      setTimeout(() => {
        audio.play()
      })
      return () => {
        audio.pause()
      }
    } else if (!playingMusic && audio) {
    }
  }, [hasTouch, url, audio, playingMusic])

  useEffect(() => {
    if (audio) {
      audio.volume = musicVolume
    }
  }, [audio, musicVolume])

  useLayoutEffect(() => {
    window.addEventListener('touchstart', () => setHasTouch(true), {
      once: true,
    })
    window.addEventListener('mousedown', () => setHasTouch(true), {
      once: true,
    })
  }, [])

  return (
    <>
      <audio ref={audioRef} hidden controls autoPlay loop src={url} />
    </>
  )
}

export function VolumeSlider(props: {
  containerClassName?: string
  inputClassName?: string
}) {
  const { playingMusic, setPlayingMusic, musicVolume, setMusicVolume } =
    useMusicState()

  const handleMusicToggle = () => {
    setPlayingMusic(prev => !prev)
  }

  const handleVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(event.target.value)
    setMusicVolume(newVolume)
    if (newVolume && !playingMusic) {
      setPlayingMusic(true)
    }
  }

  return (
    <div className={props.containerClassName}>
      <button className="nav-stop-button" onClick={handleMusicToggle}>
        <i
          className={
            !playingMusic
              ? 'fa-solid fa-volume-xmark'
              : 'fa-solid fa-volume-high'
          }
        ></i>
      </button>

      <input
        className={props.inputClassName}
        type="range"
        min="0"
        max="1"
        step="0.01"
        value={playingMusic ? musicVolume : 0}
        onChange={handleVolumeChange}
      />
    </div>
  )
}
