import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import './Gallery.css'
import {
  GetLatestScenesOutput,
  getLatestScenes,
  searchScene,
} from '../sdk/scene'
import { Locale, translate, useLang } from '../components/Locale'

type SceneResult = GetLatestScenesOutput['scenes'][number]

export default function Gallery() {
  const navigate = useNavigate()
  const { lang } = useLang()

  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<SceneResult[]>([])
  const [filters, setFilters] = useState({
    style: '',
    date: 'Any',
    hasImage: true,
    hasMusic: false,
    styleEnabled: false,
  })
  const [searchPerformed, setSearchPerformed] = useState(false)

  const [musicIcon, setMusicIcon] = useState(false)

  useEffect(() => {
    getLatestScenes({})
      .then(response => {
        if (response.error) {
          throw new Error(response.error)
        }
        setSearchResults(response.scenes)
      })
      .catch(error => {
        console.error('Failed to fetch scenes:', error)
        setSearchResults([]) // Clear results or handle error appropriately
      })
  }, [])

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    setFilters({
      ...filters,
      [name]: value,
    })
  }

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target
    if (name === 'styleEnabled') {
      setFilters({
        ...filters,
        styleEnabled: checked,
        style: checked ? filters.style : '', // Clear style if checkbox is unchecked
      })
    } else {
      if (name === 'hasImage' || name === 'hasMusic') {
        setFilters(prevFilters => ({
          ...prevFilters,
          hasImage: name === 'hasImage' ? checked : !checked,
          hasMusic: name === 'hasMusic' ? checked : !checked,
        }))
      }
    }
  }

  const performSearch = (event: MouseEvent<HTMLButtonElement>) => {
    setSearchPerformed(true)

    if (filters.hasMusic) {
      setMusicIcon(true)
    } else {
      setMusicIcon(false)
    }

    searchScene({
      body: {
        query: searchQuery,
        image: filters.hasImage,
        styleEnabled: filters.styleEnabled,
        style: filters.style,
      },
    })
      .then(response => {
        if (response.error) {
          throw new Error(response.error)
        }
        setSearchResults(response.scenes)
      })
      .catch(error => {
        console.error('Failed to search scenes:', error)
        setSearchResults([])
      })
  }

  return (
    <>
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={translate(lang, {
            en: 'Search scenes...',
            zh_cn: '搜索场景...',
            zh_hk: '搜索場景...',
          })}
        />
        <button onClick={performSearch}>
          <Locale en="Search" zh_cn="搜索" zh_hk="搜索" />
        </button>
      </div>
      <div className="filters">
        <label>
          <Locale en="Enable Style" zh_cn="启用风格" zh_hk="啟用風格" />:
          <input
            type="checkbox"
            checked={filters.styleEnabled}
            onChange={handleCheckboxChange}
            name="styleEnabled"
          />
        </label>
        <label>
          <Locale en="Style" zh_cn="风格" zh_hk="風格" />:
          <input
            type="text"
            value={filters.style}
            onChange={handleInputChange}
            disabled={!filters.styleEnabled}
            name="style"
          />
        </label>
        <label>
          <Locale en="Image" zh_cn="图像" zh_hk="圖像" />:
          <input
            type="checkbox"
            name="hasImage"
            checked={filters.hasImage}
            onChange={handleCheckboxChange}
          />
        </label>
        <label>
          <Locale en="Music" zh_cn="音乐" zh_hk="音樂" />:
          <input
            type="checkbox"
            name="hasMusic"
            checked={filters.hasMusic}
            onChange={handleCheckboxChange}
          />
        </label>
      </div>
      <div className="card-container">
        {searchResults.map(result => (
          <Link
            key={result.id}
            className="scene-card"
            to={`/app/editor/${result.id}`}
          >
            {musicIcon || !result.image_url ? (
              <img
                src="https://img.icons8.com/ios/452/music--v1.png"
                alt="music icon"
                className="music-icon"
              />
            ) : (
              <img
                src={result.image_url}
                alt={result.image_prompt}
                className="scene-image"
              />
            )}
            <div className="scene-info">
              <h2
                className="prompt-text"
                title={translate(lang, {
                  en: 'Image prompt text',
                  zh_cn: '图像提示文本',
                  zh_hk: '圖像提示文本',
                })}
              >
                <i className="fa-solid fa-image"></i> {result.image_prompt}
              </h2>
              <h3
                className="style-name"
                title={translate(lang, {
                  en: 'Image style name',
                  zh_cn: '图像风格名称',
                  zh_hk: '圖像風格名稱',
                })}
              >
                <i className="fa-solid fa-palette"></i> {result.image_style}
              </h3>
              <h2
                hidden={!result.music_prompt}
                className="prompt-text"
                title={translate(lang, {
                  en: 'Music prompt text',
                  zh_cn: '音乐提示文本',
                  zh_hk: '音樂提示文本',
                })}
              >
                <i className="fa-solid fa-music"></i> {result.music_prompt}
              </h2>
              <h3
                hidden={!result.music_style}
                className="style-name"
                title={translate(lang, {
                  en: 'Music style name',
                  zh_cn: '音乐风格名称',
                  zh_hk: '音樂風格名稱',
                })}
              >
                <i className="fa-solid fa-record-vinyl"></i>{' '}
                {result.music_style}
              </h3>
            </div>
          </Link>
        ))}
      </div>
    </>
  )
}
