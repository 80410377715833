import React from 'react'
import './SideBarApp.css'
import { Outlet } from 'react-router-dom'
import { SideBar } from '../components/SideBar'

export default function SideBarApp() {
  return (
    <div className="SideBarApp">
      <SideBar />
      <main>
        <Outlet />
      </main>
    </div>
  )
}
