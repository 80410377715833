import { Route } from 'react-router-dom'
import MainApp from './app/MainApp'
import About from './pages/About'
import Contact from './pages/Contact'
import Editor from './pages/Editor'
import Home from './pages/Home'
import Gallery from './pages/Gallery'
import Login from './pages/Login'
import NotFound from './pages/NotFound'
import Work from './pages/Work'
import Verify from './pages/top_up/Verify'
import ComingSoon from './pages/ComingSoon'
import Profile from './pages/Profile'

export let AppRoutes = (
  <>
    <Route path="/" element={<Home />} />
    <Route path="/app" element={<MainApp />}>
      <Route path="gallery" element={<Gallery />} />
      <Route path="editor" element={<Editor />} />
      <Route path="editor/:scene_id" element={<Editor />} />
      <Route path="login" element={<Login />} />
      <Route path="profile" element={<Profile />} />
      <Route path="subscription" element={<ComingSoon />} />
      <Route path="history" element={<ComingSoon />} />
      <Route path="*" element={<NotFound />} />,
    </Route>
    <Route path="/work" element={<Work />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/top_up/verify" element={<Verify />} />
    <Route path="*" element={<NotFound />} />
  </>
)
