import React, { useState, useEffect } from 'react'
import { GetUserProfileOutput, getUserProfile } from '../sdk/user'
import './Login.css'
import { useToken } from '../hooks/useToken'
import { useStateProxy } from 'use-state-proxy'
import { Link, useNavigate } from 'react-router-dom'
import { topUpCredit } from '../sdk/credit'
import { Locale } from '../components/Locale'
import LanguageOption from '../components/LanguageOption'

function Profile() {
  const state = useStateProxy({
    top_up_value: 0,
  })

  const { token, logout } = useToken()

  const [profile, setProfile] = useState<GetUserProfileOutput | null>(null)

  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      getUserProfile({}).then(setProfile)
    }
  }, [token])

  useEffect(() => {
    if (!token) {
      navigate('/app/login')
    }
  }, [token])

  // FIXME: replace localhost with actual domain
  async function requestTopUp() {
    let json = await topUpCredit({
      body: {
        unit_amount: state.top_up_value * 100,
        quantity: 1,
      },
    })

    // redirect to the payment page
    window.location.href = json.url
  }

  function renderBody() {
    if (!token) {
      return (
        <>
          <p>
            <Locale
              en="This page is only for logged in users."
              zh_cn="此页面仅限已登录用户访问。"
              zh_hk="此頁面僅限已登入用戶訪問。"
            />
          </p>
          <Link to="/app/login">
            <button>
              <Locale
                en="Login / Register now"
                zh_cn="立即登录 / 注册"
                zh_hk="立即登入 / 註冊"
              />
            </button>
          </Link>
        </>
      )
    }
    if (!profile) {
      return (
        <>
          <Locale en="Loading..." zh_cn="加载中..." zh_hk="加載中..." />
        </>
      )
    }
    return (
      <>
        <h1>
          <Locale en="Welcome, " zh_cn="欢迎，" zh_hk="歡迎，" />
          {profile.username || profile.email}
        </h1>
        <Link to="/app/history">
          <button>
            <Locale
              en="Go to My Scenes"
              zh_cn="前往我的场景"
              zh_hk="前往我的場景"
            />
          </button>
        </Link>
        <input
          style={{ color: 'black' }}
          onChange={event =>
            (state.top_up_value = parseInt(event.target.value))
          }
          placeholder="100"
          type="number"
        />
        <button onClick={requestTopUp}>
          <Locale en="Top Up" zh_cn="充值" zh_hk="充值" />
        </button>

        <button onClick={logout}>
          <Locale en="Logout" zh_cn="登出" zh_hk="登出" />
        </button>
      </>
    )
  }

  return (
    <>
      <div className="body">
        <LanguageOption />
        <div className="reg-container">{renderBody()}</div>
      </div>
    </>
  )
}

export default Profile
