import { Outlet } from 'react-router-dom'
import BottomBar from '../components/BottomBar'
import './BottomBarApp.css'

export default function BottomBarApp() {
  return (
    <div className="BottomBarApp">
      <main>
        <Outlet />
      </main>
      <BottomBar />
    </div>
  )
}
