// This file is generated automatically
// Don't edit this file directly

import { call, toParams } from './utils'

export let api_origin = '/api'

// GET /api/suno/styles
export function getSunoStyleList(input: GetSunoStyleListInput): Promise<GetSunoStyleListOutput & { error?: string }> {
  return call('GET', api_origin + `/suno/styles`)
}
export type GetSunoStyleListInput = {
}
export type GetSunoStyleListOutput = {
  styles: Array<string>
}
