import { Link } from 'react-router-dom'
import { getMenuItems, MenuItem } from './MenuItems'
import './BottomBar.css'
import { translate, useLang } from './Locale'
import { useRole } from '../hooks/useRole'

// TODO take reference on the side menu
export default function BottomBar() {
  const role = useRole()
  const menuItems = getMenuItems({ role })
  return (
    <nav className="bottom-menu">
      <ul>
        {menuItems.map(item => (
          <BottomBarMenuItem key={item.label.en} {...item} />
        ))}
      </ul>
    </nav>
  )
}

function BottomBarMenuItem(props: MenuItem) {
  const { lang } = useLang()
  const label = translate(lang, props.label)
  return (
    <li>
      <Link to={props.href} title={label}>
        <div className="icons">
          <i className={props.icon}></i>
        </div>
        <div className="label">{label}</div>
      </Link>
    </li>
  )
}
